<script setup>
const route = useRoute();
const topPage = ref();
const backendStore = useBackendStore();
const { isEmbed } = backendStore;
const carCount = backendStore.carCount.value;
const carsAvailable = computed(() => cars.value.data?.filter(car => car.price).sort((a, b) => a.modelName > b.modelName ? 1 : -1));
const carsAvailableCount = computed(() =>
  carsAvailable.value.reduce((sum, car) =>
    sum + (car.count || 0) + (car.usedCount || 0) + (car.postLeaseCount || 0), 0
  )
);
const carsUnavailable = computed(() => cars.value.data?.filter(car => !car.price).sort((a, b) => a.modelName > b.modelName ? 1 : -1));
const backend = useBackend();
const badRoutes = useBadRoutes();

const { error } = await useAsyncData(() => new Promise(async (resolve, reject) => {
  try {
    const brands = await backend.getBrands();
    const brandExists = brands.data.find(
      i => toLowerDashed(i.name) === toLowerDashed(route.params.brand)
    );
    if (!brandExists) {
      reject(new Error('Brand does not exist'));
      return;
    }

    resolve(true);
  } catch (err) {
    reject(err);
  }
}));

if (error.value) {
  badRoutes.handle();
}

const {data: cars} = await useAsyncData(() => new Promise(async resolve => {
  const models = await backend.getModels({brand: route.params.brand});
  resolve(models)
}))

const brand = computed(() => route.params.brand?.toUpperCase())

useHead({
  title: `Leasing ${brand.value} - wybierz model i sfinansuj on-line!`,
  meta: [
    {name: 'description', content: `${brand.value} w leasingu dla przedsiębiorców i konsumentów ✔️ Decyzja leasingowa w 15 min ✔️ Szeroki wybór modeli i atrakcyjne rabaty ✔️ Sprawdź!`}
  ],
  link: [
    {rel: 'canonical', href: 'https://vehis.pl'+route.path}
  ]
});

</script>

<template>
  <Container no-paddings>
    <AppTop :includesBrand="brand" />
    <div class="pb-10" id="top">
      <LayoutColumns useEmbedState>
        <LayoutMainCarsColumn ref="topPage">
          <div :class="!isEmbed ? 'md:col-span-2 lg:col-span-2 xl:col-span-3 -mb-6' : 'col-span-2 md:col-span-2 lg:col-span-3 xl:col-span-4'">
            <MainContentHeader :counter="carsAvailableCount">Oferta samochodów {{ brand }} w leasingu</MainContentHeader>
          </div>
          <template v-for="car in carsAvailable">
            <CarTile :car="car" />
          </template>
          <template v-if="carsUnavailable.length && !isEmbed">
            <div class="md:col-span-2 lg:col-span-2 xl:col-span-3 -my-6">
              <SubContentHeader>Modele dostępne w ofercie indywidualnej</SubContentHeader>
            </div>
            <template v-for="car in carsUnavailable">
              <CarTile :car="car" unavailable />
            </template>
          </template>
        </LayoutMainCarsColumn>
        <LayoutSideColumn v-if="!isEmbed" class="mt-20">
          <FormContact :car="carsAvailable[0] || carsUnavailable[0]" />
        </LayoutSideColumn>
      </LayoutColumns>
    </div>
  </Container>

  <Container v-if="!isEmbed">
    <SeoMoreAboutModels :cars="cars.data" :title="`Modele ${brand}:`" />
  </Container>

  <Container v-if="!isEmbed" with-border-top>
    <SeoBrand />
  </Container>

  <Container v-if="!isEmbed" with-border-top>
    <BlockHowTo :steps="[`Wybierz samochód z ponad <b>${carCount}</b> dostępnych ofert.`,'<b>Wspólnie skalkulujemy</b> ofertę<br/>finansowania i ubezpieczenia.','Finansowanie przyznamy<br/><b>w 15 minut</b> od złożenia wniosku.','Samochód odbierzesz nawet<br/><b>w 24h</b> od podpisania umowy.']" />
  </Container>

  <Container v-if="!isEmbed" gray>
    <BlockFeaturesComparedText />
  </Container>

  <Container v-if="!isEmbed">
    <FormContact title="W każdym momencie możesz skorzystać z&nbsp;pomocy naszych Doradców" standalone />
  </Container>

  <Container v-if="!isEmbed" with-border-top>
    <SeoBrandsCloud />
  </Container>
</template>
